import _ from 'lodash';

export const CARDS = {
  posts: [
    {
      id: 1,
      category: 'Recipes',
      title:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
      text:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
      image: '/images/menu768x768px1.jpg',
      publishedAt: 'Wed, 12-02-2018',
      postBy: 'Wed Admin',
      views: '201'
    },
    {
      id: 2,
      category: 'Uncategorized',
      title:
        'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
      text:
        "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
      image: '/images/menu768x768px2.jpg',
      publishedAt: 'Wed, 12-02-2018',
      postBy: 'Owner',
      views: '23'
    },
    {
      id: 3,
      category: 'Meals',
      title:
        'Contrary to popular belief, Lorem Ipsum is not simply random text',
      text:
        'It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      image: '/images/menu768x768px3.jpg',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Lead Chef',
      views: '120'
    },
    {
      id: 4,
      category: 'Steaks',
      title:
        'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
      text:
        'The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      image: '/images/menu768x768px4.jpg',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '321'
    },
    {
      id: 5,
      category: 'Random',
      title:
        'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
      text:
        'The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      image: '/images/menu768x768px5.jpg',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '435'
    },
    {
      id: 6,
      category: 'Uncategorized',
      title:
        'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
      text:
        'The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
      image: '/images/menu768x768px6.jpg',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    }
  ],
  menus: [
    {
      id: 1,
      category: 'steaks',
      image: '/images/menu768x768px1.jpg',
      title: 'Eggplant Parmigiana Salad',
      description:
        'Fried eggplant, mixed greens, fresh mozzarella, thinly sliced cherry pepper, tomato',
      price: '12.2',
      label: 'Hot Plate',
      tag: 'success',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 2,
      category: 'dinner',
      image: '/images/menu768x768px2.jpg',
      title: 'Grilled Portobello Salad',
      description:
        'Fresh mozzarella, roasted red peppers, basil, extra virgin olive oil, balsamic glaze.',
      price: '10',
      label: 'Popular',
      tag: 'warning',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 3,
      category: 'breakfast',
      image: '/images/menu768x768px3.jpg',
      title: 'Bed and Breakfast',
      description:
        'Grilled chicken, grilled zucchini, fresh mozzarella, roasted pepper, roasted red onion.',
      price: '7.5',
      label: 'Hot Plate',
      tag: 'success',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 4,
      category: 'meals',
      image: '/images/menu768x768px4.jpg',
      title: 'Tenderloin',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
      price: '22.2',
      label: 'Popular',
      tag: 'danger',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 5,
      category: 'dinner',
      image: '/images/menu768x768px5.jpg',
      title: 'Lamb Chops',
      description:
        'Lightly breaded breast of chicken topped with sautéed diced tomatoes, red onions and melted fresh mozzarella.',
      price: '15',
      label: 'Hot Plate',
      tag: 'warning',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 6,
      category: 'meals',
      image: '/images/menu768x768px6.jpg',
      title: 'Sautéed Egg Roll',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
      price: '7.7',
      label: 'Hot Plate',
      tag: 'warning',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 7,
      category: 'desert',
      image: '/images/menu768x768px1.jpg',
      title: 'Margarine Eggs',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
      price: '4.5',
      label: 'Nice Deal',
      tag: 'warning',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 8,
      category: 'steaks',
      image: '/images/menu768x768px2.jpg',
      title: 'Steaks Eggs',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
      price: '8.8',
      label: 'Popular',
      tag: 'warning',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 9,
      category: 'steaks',
      image: '/images/menu768x768px3.jpg',
      title: 'Steaks Fillet Eggs',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
      price: '9.3',
      label: 'Nice Deal',
      tag: 'success',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 10,
      category: 'steaks',
      image: '/images/menu768x768px4.jpg',
      title: 'Fillet Egg Bread',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
      price: '5.2',
      label: 'Nice Deal',
      tag: 'danger',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 11,
      category: 'hams',
      image: '/images/menu768x768px5.jpg',
      title: 'Spicy Scarpariello Wings',
      description:
        'Breast of chicken sautéed with lemon, white wine, capers and artichoke hearts.',
      price: '22.23',
      tag: 'info',
      label: 'Hot Plate',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    },
    {
      id: 12,
      category: 'hams',
      image: '/images/menu768x768px6.jpg',
      title: 'Roasted Peppers w/ Anchovies with Mozzarella',
      description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
      price: '11.5',
      tag: 'warning',
      label: 'Popular',
      publishedAt: 'Wed, 12-02-2019',
      postBy: 'Chief Kitchen',
      views: '98'
    }
  ],
  employees: [
    {
      id: 1,
      name: 'Curtis Biafrah Amarek',
      title: 'Div Manager',
      about:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      photo: '/images/profile2.jpg'
    },
    {
      id: 2,
      name: 'JB Moreno Savannah',
      title: 'Div Staff',
      about:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      photo: '/images/profile9.jpg'
    },
    {
      id: 3,
      name: 'Micah Milacjovic',
      title: 'Div Senior',
      about:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      photo: '/images/profile5.jpg'
    },
    {
      id: 4,
      name: 'Steinley Coihr',
      title: 'Div Manager',
      about:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      photo: '/images/profile10.jpg'
    },
    {
      id: 5,
      name: 'Illyass Hasyid Adnan',
      title: 'Div Staff',
      about:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      photo: '/images/profile11.jpg'
    },
    {
      id: 6,
      name: 'Nino Franklin Jones',
      title: 'Div Senior',
      about:
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      photo: '/images/profile5.jpg'
    }
  ]
};

export const CARD_STATUS_CODE = {
  WAITING_ACTIVATION: '1',
  ACTIVE: '2',
  INACTIVE: '0',
  CANCEL: '-1',
  BLOCKED: '-2'
};

export const CARD_REQUEST_CODE = {
  REQUEST_ACTIVATION: '1',
  REQUEST_LIMIT: '2',
  REQUEST_CANCEL: '-1',
  REQUEST_BLOCK: '-2'
};

export const CARD_STATUS = {
  STATUS: {
    [CARD_STATUS_CODE.WAITING_ACTIVATION]: 'NEED TO ACTIVATE',
    [CARD_STATUS_CODE.ACTIVE]: 'ACTIVE',
    [CARD_STATUS_CODE.INACTIVE]: 'INACTIVE',
    [CARD_STATUS_CODE.CANCEL]: 'CANCEL',
    [CARD_STATUS_CODE.BLOCKED]: 'BLOCKED'
  },
  REQUEST: {
    [CARD_REQUEST_CODE.REQUEST_ACTIVATION]: 'REQUEST ACTIVATION',
    [CARD_REQUEST_CODE.REQUEST_LIMIT]: 'REQUEST LIMIT',
    [CARD_REQUEST_CODE.REQUEST_CANCEL]: 'REQUEST CANCEL',
    [CARD_REQUEST_CODE.REQUEST_BLOCK]: 'REQUEST BLOCK'
  }
};

export const CARD_STATUS_TAG_COLOR = {
  [CARD_STATUS_CODE.INACTIVE]: 'grey',
  [CARD_STATUS_CODE.CANCEL]: 'grey',
  [CARD_STATUS_CODE.BLOCKED]: 'red',
  [CARD_STATUS_CODE.ACTIVE]: 'green',
  [CARD_STATUS_CODE.WAITING_ACTIVATION]: 'yellow'
};

export const CARD_STATUS_UPDATE_REQUEST_TYPE = {
  BLOCK: 'BLOCK',
  CANCEL: 'CANCEL',
  LIMIT: 'LIMIT'
};

export const getCardStatusText = (status, request = 0) =>
  request !== 0
    ? _.get(CARD_STATUS.REQUEST, request, '')
    : _.get(CARD_STATUS.STATUS, status, '');

export const CARD_TRANSACTION_STATUS = {
  PENDING: 'Pending',
  POSTED: 'Posted'
};

export const CARD_TRANSACTION_STATUS_TAG_COLOR = {
  [CARD_TRANSACTION_STATUS.PENDING]: 'warning',
  [CARD_TRANSACTION_STATUS.POSTED]: 'success'
};

export const CARD_TYPE = {
  VIRTUAL: 'VIRTUAL',
  PHYSICAL: 'PHYSICAL'
};
