export const getPreviousVisitedURL = () => {
  if (typeof window !== 'undefined') {
    const prevVisitedURL = localStorage.getItem('previousVisitedURL');

    if (prevVisitedURL) {
      return prevVisitedURL;
    }
  }

  return null;
};

export const setVisitedURL = visitedURL => {
  localStorage?.setItem('previousVisitedURL', visitedURL);
};

export const resetPreviousVisitedURL = () => {
  localStorage?.removeItem('previousVisitedURL');
};

export const getForgotPasswordNextPage = url => {
  const nextPageUrl = localStorage?.getItem('forgotPasswordNextPAge', url);

  if (nextPageUrl) {
    return nextPageUrl;
  }
  return null;
};

export const setForgotPasswordNextPage = url => {
  localStorage?.setItem('forgotPasswordNextPAge', url);
};

export const resetForgotPasswordNextPage = url => {
  localStorage?.removeItem('forgotPasswordNextPAge');
};
