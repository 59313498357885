import { GATEWAY_BASE_API_URL } from '@configs/keys';
import { COUNTRY_CURRENCY } from '@constants/country';
import { COMPANY_ROLE } from '@constants/role';
import { SCOPE } from '@constants/scope';
import { setCountryCode } from '@helpers/countryCode';
import {
  getPreviousVisitedURL,
  resetPreviousVisitedURL
} from '@helpers/previousUrl';
import {
  getToken,
  removeToken,
  removeTokenRole,
  setCompanySession,
  setToken,
  setTokenRole
} from '@helpers/token';
import axios from 'axios';
import _ from 'lodash';
import Router from 'next/router';
import Swal from 'sweetalert2';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  };
}

function receiveLogin(data) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: data.token,
    payload: data
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

export function loginUser(data) {
  return async dispatch => {
    dispatch(requestLogin(data));
    return axios
      .post(`${GATEWAY_BASE_API_URL}/identity-provider/login`, data)
      .then(response => {
        dispatch(receiveLogin(response.data));
        const { payload } = response.data;
        const defaultCompanySession = payload?.company;
        const roles = [...payload?.user_roles];

        if (!defaultCompanySession) {
          Swal.fire(
            'Error!',
            'Sorry your account is inactive, please contact admin!',
            'error'
          );

          return;
        }

        const countryCode = defaultCompanySession?.country_code;
        if (!defaultCompanySession.status) {
          Swal.fire(
            'Error!',
            'Sorry your account is inactive, please contact admin!',
            'error'
          );
        } else if (!countryCode || !COUNTRY_CURRENCY[countryCode]) {
          Swal.fire(
            'Error!',
            `Sorry could not identify country: ${countryCode}, please contact admin!`,
            'error'
          );
        } else {
          const rolesByScope = _.keyBy(roles, 'scope');

          // TODO: To be removed once all scopes go live
          const cccRole = rolesByScope[SCOPE.CORPORATE_CREDIT_CARD];
          const cccRoleCode = cccRole?.code ?? COMPANY_ROLE.CARD;

          setCompanySession(defaultCompanySession);
          setToken(`Bearer ${response.data.access_token}`);
          setTokenRole(cccRoleCode);
          setCountryCode(countryCode);

          localStorage.setItem('user', payload.name);

          const returnURL = getPreviousVisitedURL();
          if (returnURL) {
            window.location.href = returnURL;
            resetPreviousVisitedURL();

            return;
          }

          // Redirect based on roles
          let redirectURL = '/dashboard/overview';
          if (rolesByScope[SCOPE.CORPORATE_CREDIT_CARD]) {
            redirectURL = '/dashboard/overview';
          } else if (rolesByScope[SCOPE.SPEND]) {
            redirectURL = '/dashboard/spend/transactions';
          } else if (rolesByScope[SCOPE.REIMBURSEMENT]) {
            redirectURL = '/dashboard/reimbursement/transactions';
          } else if (rolesByScope[SCOPE.MANAGEMENT]) {
            redirectURL = '/dashboard/payroll/report';
          }

          window.location.href = redirectURL;
        }
      })
      .catch(err => {
        if (err.response?.status === 401) {
          dispatch(loginError('no_permission'));
        } else {
          dispatch(loginError('invalid_credentials'));
        }
      });
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
}

export function logoutUser(redirectTo = '/') {
  return dispatch => {
    dispatch(requestLogout());
    removeToken();
    removeTokenRole();
    dispatch(receiveLogout());
    Router.push(redirectTo);
  };
}

export const changePass = data => {
  const token = getToken() || null;
  axios.defaults.headers.common.Authorization = `${token}`;
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST, loading: true });
    return axios({
      method: 'PUT',
      url: `${GATEWAY_BASE_API_URL}/identity-provider/password`,
      data
    }).then(
      response => {
        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
        Swal.fire('Success!', 'Success change password!', 'success').then(
          () => {
            window.location.href = '/dashboard/overview';
          }
        );
      },
      error => {
        dispatch({ type: CHANGE_PASSWORD_FAILURE, error: error.response });

        Swal.fire({
          title: 'Error',
          text: error.response
            ? 'wrong old password!'
            : 'Please check your connection',
          icon: 'error'
        });
        if (error.response.status === 401) {
          dispatch(logoutUser());
        }
        // throw error;
      }
    );
  };
};

export const forgotPass = data => {
  const token = getToken() || null;
  axios.defaults.headers.common.Authorization = `${token}`;
  return dispatch => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST, loading: true });
    return axios({
      method: 'POST',
      url: `${GATEWAY_BASE_API_URL}/identity-provider/password/forgot`,
      data
    }).then(
      response => {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response.data });

        window.location.href = '/success';
      },
      error => {
        dispatch({ type: FORGOT_PASSWORD_FAILURE, loading: false });
        Swal.fire('Error!', 'Email not found', 'error');
        if (error.response.status === 401) {
          dispatch(logoutUser());
        }
        throw error;
      }
    );
  };
};

export const resetPassword = data => {
  const token = getToken() || null;
  axios.defaults.headers.common.Authorization = `${token}`;
  return dispatch => {
    dispatch({ type: RESET_PASSWORD_REQUEST, loading: true });
    return axios({
      method: 'POST',
      url: `${GATEWAY_BASE_API_URL}/identity-provider/password/reset/dashboard`,
      data
    }).then(
      response => {
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
        Swal.fire('Success!', 'Change password success', 'success').then(() => {
          window.location.href = '/login';
        });
      },
      error => {
        dispatch({ type: RESET_PASSWORD_FAILURE, error: error.response });
        Swal.fire({
          title: 'Error',
          text:
            'Reset password link is invalid/expired, please try to submit forgot password again!',
          icon: 'error'
        });
        if (error.response.status === 401) {
          dispatch(logoutUser());
        }
        throw error;
      }
    );
  };
};
